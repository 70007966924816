<template>
  <div>
    <div class="header">
      <van-nav-bar
        :title="$t('shop.title')"
        left-arrow
        :right-text="$t('shop.title2')"
        @click-left="$router.go('-1')"
        @click-right="$router.push('/trade/shopping/trading')"
      />
    </div>
    <div class="maincontent" style="background: #181e34;">
      <van-tabs class="main_tab" title-active-color="#ff571e" title-inactive-color="#cad0e6">
        <van-tab :title="$t('shop.buy')">
          <van-tabs class="main_tab" title-active-color="#ff571e" title-inactive-color="#cad0e6">
            <van-tab title="USDT">
              <div
                class="main"
                v-for="(item, index) in buylist"
                :key="item.id"
                @click="open(index, item.id)"
              >
                <div class="username">{{ item.nickname || item.username }}</div>
                <div class="range">
                  <div>
                    <b>{{ $t('shop.single') }}：</b>
                    <b>{{ item.number_min }}</b>
                    <b>-</b>
                    <b>{{ item.number_max }}</b>
                  </div>
                  <div>
                    <b>{{ item.price }}</b>
                    <b>{{ item.unit }}</b>
                  </div>
                </div>
                <div class="unit"></div>
                <van-row class="num">
                  <van-col :span="12" style="text-align:left">
                    <b>{{ $t('shop.number') }}：</b>
                    <b>
                      {{ item.number_total - item.trade }}
                    </b>
                  </van-col>
                  <van-col :span="5" offset="7">
                    <van-button type="primary" size="small">{{
                      $t('shop.tobuy')
                    }}</van-button>
                  </van-col>
                </van-row>
              </div>
              <!-- 购买弹出层 -->
              <van-action-sheet v-model="isshow" @open="action_open">
                <div class="content">
                  <div class="main" v-if="buylist[index]">
                    <van-count-down
                      ref="countDown"
                      :time="time"
                      format="ss"
                      class="count-down-time"
                      @finish="finish"
                    />
                    <span style="color: rgb(217, 0, 18);"
                      >S{{ $t('shop.cancel') }}</span
                    >
                    <!-- 价格 -->
                    <div class="price">
                      <span>{{ $t('shop.price') }}：</span>
                      <span style="font-size:50px">{{
                        buylist[index].price
                      }}</span>
                      <span>{{ buylist[index].unit }}</span>
                    </div>
                    <div class="range">
                      <div>
                        <b>{{ $t('shop.single') }}：</b>
                        <b>{{ buylist[index].number_min }}</b>
                        <b>-</b>
                        <b>{{ buylist[index].number_max }}</b>
                      </div>
                    </div>
                    <van-form>
                      <div class="edit-num">
                        <van-field
                          v-model="buynum"
                          name="buynum"
                          :placeholder="$t('shop.input')"
                          :label="$t('shop.number')"
                          :rules="[{ required: true, message: '请输入数量' }]"
                        />
                      </div>
                      <van-button class="submit" @click="submit">{{
                        $t('common.confirm')
                      }}</van-button>
                    </van-form>
                  </div>
                </div>
              </van-action-sheet>
            </van-tab>
          </van-tabs>
        </van-tab>
        <van-tab :title="$t('shop.sell')">
          <van-tabs class="main_tab" title-active-color="#ff571e" title-inactive-color="#cad0e6">
            <van-tab title="USDT">
              <van-row>
                <van-button
                  class="submitBtn"
                  @click="$router.push('/trade/shopping/sell')"
                  >{{ $t('shop.sell') }}</van-button
                >
              </van-row>
            </van-tab>
          </van-tabs>
        </van-tab>
      </van-tabs>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      buylist: [],
      isshow: false,
      time: 60 * 1000,
      buynum: '',
      index: 0,
      buyid: '',
      oauth: ''
    }
  },
  created() {
    this.getlist()
  },
  methods: {
    async getlist() {
      const { data } = await this.$http.get('/home/trade/buylist')
      if (data) {
        if (data.code === 200) {
          this.buylist = data.data.list
          this.oauth = data.data.oauth
        }
      }
    },
    open(index, buyid) {
      if (this.oauth < 2) {
        this.$toast.fail(this.$t('shop.sfrz'))
        return false
      }
      this.index = index
      this.buyid = buyid
      this.isshow = true
    },
    action_open() {
      this.$nextTick(() => {
        this.$refs.countDown.reset()
      })
    },
    // @finish="finish"
    finish() {
      this.isshow = false
    },
    async submit() {
      const postform = { buyid: this.buyid, buynum: this.buynum }
      const { data } = await this.$http.post('/home/trade/buy', postform)
      if (data) {
        if (data.code === 200) {
          this.$toast.success(this.$t('common.success'))
          this.isshow = false
          this.$router.push('/trade/shopping/trading/buy_detail/' + data.data)
        } else {
          this.$toast.fail(this.getlang(data.msg, localStorage.getItem('lang')))
        }
      }
    }
  }
}
</script>
<style lang="less" scoped>
.header {
  /deep/.van-nav-bar__title {
    font-size: 1rem;
  }
  /deep/.van-nav-bar__text {
    font-size: 0.98667rem;
    color: #2c3e50;
  }
}
.maincontent {
  padding: 66px 0;
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  font-size: 0.93333rem;
  background: #fff;
  height: 100vh;
  /deep/.van-tab {
    color: grey;
    font-size: 1.04rem;
  }
  /deep/.van-tab--active {
    color: #004ea3;
  }
  //购买弹出层
  .main {
    margin: 0 1.06667rem;
    border-bottom: 0.02667rem solid #e5e5e5;
    padding-bottom: 0.53333rem;
    .username {
      font-size: 0.96rem;
      margin: 0.8rem 0;
      text-align: left;
      margin: 0.53333rem 0;
      color: #212121;
    }
    .num {
      display: flex;
      align-items: center;
      button {
        width: 100%;
        height: 2.13333rem;
        background-color: #ff571e;
        color: #fff;
        border: none;
        border-radius: 0.26667rem;
        font-size: 0.93333rem;
        white-space: nowrap;
      }
    }
    .range {
      display: flex;
      justify-content: space-between;
    }
    b {
      color: grey;
      font-weight: 500;
    }
    .unit {
      height: 0.26667rem;
    }
  }
  .van-popup--bottom.van-popup--round {
    border-radius: 0;
  }
  .van-action-sheet__header {
    background-color: #fff;
    color: #fff;
    font-size: 0.8rem;
    text-align: left;
    padding-left: 1.06667rem;
  }
  .num,
  .price,
  .range,
  .unit,
  .username {
    text-align: left;
  }
  .content {
    height: 18.66667rem;
    background-color: #fff;
    .count-down-time {
      display: inline-block;
      font-size: 1.33333rem;
      color: #d90012;
    }
    .main b {
      color: #424c66;
      font-size: 0.8rem;
      font-weight: 500;
    }
    .price,
    .range {
      margin-left: 4%;
      span {
        color: #000;
        font-size: 0.8rem;
      }
    }
    .edit-num {
      .van-cell {
        background-color: #fff;
        height: 3.73333rem;
      }
      /deep/.van-field__label {
        color: #000;
        width: auto;
      }
      /deep/.van-field__control {
        border-bottom: 0.02667rem solid #d0d0d1;
        margin-right: 1.6rem;
        color: #000;
      }
    }
    .submit {
      width: 18.66667rem;
      height: 2.93333rem;
      margin: 0.8rem auto 0;
      letter-spacing: 0.53333rem;
      font-size: 0.93333rem;
      color: #fff;
      background-color: #ff571e;
      border: none;
      border-radius: 0.53333rem;
    }
  }
  .submitBtn {
    margin-top: 5%;
    margin-bottom: 5%;
    width: 60%;
    letter-spacing: 0.53333rem;
    height: 3.46667rem;
    font-size: 0.93333rem;
    color: #fff;
    background-color: #ff571e;
    border-radius: 0.53333rem;
    border: none;
  }
}
</style>
